import React from 'react';
import Aside from './aside';
import Picture1 from '../img/banner2.jpg';

const Start = () => {
    return (
      <div className="Start">
            <article>
                <section className='startBildContainer'>
                    <figure>
                        <img className='startBild' src={Picture1} alt="Logotype for Tournament"/>
                    </figure>
                </section>
                <section className='startText'>
                    <h1>Quality Hockey Games</h1>
                    <p>Hej och varmt välkomna till det första av förhoppningsvis många Quality Hockey Games.<br/> Det är med engagemang och passion vi
                        värkt fram det här och vi hoppas att ni kommer tycka det är lika roligt som vi gör!
                    </p>
                    <p>På den här sidan kommer ni kunna hitta all information kopplad till turneringen. <br/> Ni kan se information om lagen, spelscheman, 
                        regler och så vidare.
                    </p>
                    <p>Tack för att ni är en del utav den här resan med oss, det betyder oerhört mycket.</p>
                    <p>Lycka till och må bäste spelare vinna!</p>
                    <p><i>Marcus, Jimmy och Joakim</i></p>
                </section>
            </article>
            <aside>
                <Aside/>
            </aside>
      </div>
    );
  }
  
  export default Start;