import React from 'react';
import { NavLink } from "react-router-dom";

const Teamsbar = () => {
    return (
      <div className="Teamsbar">
        <article>
          <section className='teamsBar'>
            <ul>
                <li><NavLink to="IF" className={({ isActive }) => (isActive ? 'active' : '')}>Inköps Finest</NavLink></li>
                <li><NavLink to="RoadMapHockey" className={({ isActive }) => (isActive ? 'active' : '')}>RoadMap Hockey</NavLink></li>
                <li><NavLink to="HM" className={({ isActive }) => (isActive ? 'active' : '')}>Hattarna och Mössorna</NavLink></li>
                <li><NavLink to="Legenderna" className={({ isActive }) => (isActive ? 'active' : '')}>Legenderna</NavLink></li>
                <li><NavLink to="TheBigRedMachine" className={({ isActive }) => (isActive ? 'active' : '')}>The Big Red Machine</NavLink></li>
                <li><NavLink to="TB" className={({ isActive }) => (isActive ? 'active' : '')}>Team Brynäs</NavLink></li>
                <li><NavLink to="GladaKakor" className={({ isActive }) => (isActive ? 'active' : '')}>Glada Kakor</NavLink></li>
                <li><NavLink to="RG" className={({ isActive }) => (isActive ? 'active' : '')}>Ronneby Gräsänder</NavLink></li>
            </ul>
          </section>
        </article>   
      </div>
    );
  }
  
  export default Teamsbar;