import React from 'react';
import { NavLink } from "react-router-dom";

const Newsbar = () => {
    return (
      <section className="NewsbarWrapper">
        <NavLink to="News" className={({ isActive }) => (isActive ? 'active' : '')}>
          <section className='NewsBar'>
                <p>Se dem senaste intervjuerna och nyheterna här!</p>
          </section>
        </NavLink>
      </section>
    );
  }
  
  export default Newsbar;