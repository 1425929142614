import React from 'react';
import coach from '../../img/ho/coach.png'
import team from '../../img/ho/team.png'

const Legenderna = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                  <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className="HenkeTeam" src={team} alt="" />
                    </figure>
                </section>
                <section className='TeamTextInfo'>
                </section>
          </article>
          <aside className='TeamAside'>
          <h2>Tränarfoto</h2>
              <figure className="CoachPhoto">
                <img className='HenkeCoach' src={coach} alt=""/>
              </figure>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td className='tablePos'>M</td>
                            <td>Pelle Lindbergh</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VB</td>
                            <td>Roland Stoltz</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HB</td>
                            <td>Lennart ”Lill-Strimma” Svedberg</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>C</td>
                            <td>Ulf Sterner</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VF</td>
                            <td>Kent Nilsson</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HF</td>
                            <td>Anders Hedberg</td>
                        </tr>
                    </table>
                    <br/>
                    <h2>Styrkor</h2>
                    <p>Allt.</p>
                    <h2>Svagheter</h2>
                    <p>Finns inga.</p>
              </section>
          </aside>     
      </div>
    );
  }
  
  export default Legenderna;