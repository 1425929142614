import React from 'react';
import team from '../../img/ug/team.PNG'

const TB = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                  <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className='UlfTeam' src={team} alt="" />
                    </figure>
                </section>
                <section className='TeamTextInfo'>
                </section>
          </article>
          <aside className='TeamAside'>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td className='tablePos'>M</td>
                            <td>Willy Löfqvist</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VB</td>
                            <td>Stig Salming</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HB</td>
                            <td>Börje Salming</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>C</td>
                            <td>Tord Lundström</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VF</td>
                            <td>Mats Näslund</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HF</td>
                            <td>Tomas Sandström</td>
                        </tr>
                    </table>
                    <h2>Kommentar ifrån Coachen</h2>
                      <p>
                        "Var har mitt lag för ranking? 1á?" - <i>Ulf G.</i>
                      </p>
              </section>
          </aside>     
      </div>
    );
  }
  
  export default TB;