import React from 'react';
import coach from '../../img/jo/coach.jpg'
import team from '../../img/jo/team.png'

const HM = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                  <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className='JockeTeam' src={team} alt="" />
                    </figure>
                </section>
                <section className='TeamTextInfo'>
                      <h3>Frågor till tränaren</h3>
                      <h4>Vad tror du om era chanser?</h4>
                      <p>- Jag tror stenhårt på detta laget, vi har spenderat 4 veckor i Florida på pensionat Ice sommerland.</p>
                      <h4>Vad tror du om era motståndare?</h4>
                      <p>- Nästan en skrattretande fråga ryter Bert, och lämnar först rummet.<br/> - Vad fan tror du? Folk snackar om riggad lottning och jag är beredd att hålla med, fick den klart tuffaste gruppen</p>
                      <h4>Vad menar du egentligen?</h4>
                      <p>- Jag förstår inte hur dom två absolut sämsta lagen kan hamna i samma grupp!</p>
                      <h4>Vilka är det du pratar om?</h4>
                      <p>- Spela inte dum, det förstår nog alla säger Bert och lämnar studion röd i hela ansiktet</p>
                </section>
          </article>
          <aside className='TeamAside'>
          <h2>Sportchef</h2>
              <figure className="CoachPhoto">
                <img className="JoakimCoach" src={coach} alt=""/>
              </figure>
          <h2>Laginfo</h2>
              <p>Laget grundades 1970 efter en fest hemma hos Bert Karlsson</p>
              <p>Det blev dock genast bråk i laget då Bert ville göra en utvikningskalender för att dra in lite extra stålar, idag är alla vänner och spenderar sin fritid med att trolla folk på flashback.</p>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td className='tablePos'>M</td>
                            <td>Thorbjön Fälldin</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VB</td>
                            <td>Leif Pagrotsky</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HB</td>
                            <td>Carl Bildt</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>C</td>
                            <td>Ian Wachtmeister</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VF</td>
                            <td>Gudrun Schyman</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HF</td>
                            <td>Birgit Friggebod</td>
                        </tr>
                    </table>
              </section>
          </aside>     
      </div>
    );
  }
  
  export default HM;