import React from 'react';
import coach from '../../img/mr/coach.jpg'
import asscoach from '../../img/mr/gerrysand.jpg'
import team from '../../img/mr/team.jpg'
import logo from '../../img/mr/logo.png'

const RG = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                  <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className="MarcusTeam" src={team} alt="" />
                    </figure>
                </section>
                <section className='TeamTextInfo'>
                      <h2>Laginfo</h2>
                      <p> Ronneby Gräsänder bildades i Januari 2024, när bygden skrek efter ett nytt lag att älska, då det nuvarande laget KRIF Hockeys
                        sportsliga prestationer varit under all kritik i en period på många år. Kommunstyrelsen tillsammans med en utav eldsjälarna i bygden,
                        Marcus Ronnmar, skred snabbt till verket och startade upp en ny förening för att blidka allmänheten.
                      </p>
                      <p>Först var det tänkt att laget skulle heta "Ronneby Drakar" - då med anspegling på att en manlig gräsand kan tituleras som "Drake", men 
                        det fick inte genomslag i bygden. Man var rädd då nivån av bildade människor som begriper vad en drake är, sjunkit i takt med att arbetskraft 
                        ifrån både västra och östra kommuner i länet nu börjat arbeta inom Ronneby Kommun, och inte har samma kunskaper om gräsänder.
                      </p>
                      <p>
                        När namn och grafisk profil var fastslagen så gav man, "Coach Ronnmar" och "Ass. Coach Gerry Sand", order om att starta igång inköp av material och vinterträning
                        då föreningen blivit inbjuden till "Quality Hockey Games" - som går av stapeln här i Februari. Nu hoppas föreningen att laget med Herr Ronnmar i spetsen 
                        lyckas kamma hem en pallplats och visa redan ifrån start att det här är folkets nya förening i Ronneby Kommun.
                      </p>
                      <h3>Intervju med Coachen</h3>
                      <h4>Hur känns det i laget just nu?</h4>
                      <p>- Jo men det känns bra som fan, killarna är laddade. Vi har kört igång ganska hårt här nu inför turneringen, men grabbarna visar på ett jävla anamma
                        på träningarna och även på lediga stunder.
                      </p>
                      <h4>Vad kul! Har ni hunnit visa er i byn och känner ni att ni har stödet med er?</h4>
                      <p>- Ja men fyfan absolut! Vi var på Blekan och käkade pasta igår och det var många som kom förbi och snackade med grabbarna. Kul som fan att se Willy komma ur skalet lite också. 
                        Jag och Gerry satt och drack en bira och snackade taktik, men vi fick skriva lite autografer och sånt med.<br/>
                      </p>
                      <h4>Vad tror du om era chanser här i turneringen? Vilka skulle du tippa är hetast av motståndarna?</h4>
                      <p>- Äh, det är väl lite tidigt att säga nu vad våra chanser är, men jag tror på avancemang iaf. Så länge vi inte kommer sist
                        i gruppen så är väl bygden också nöjd, KRIF är ju skit just nu. Vi har haft många scouter runtomkring "RoadMap Hockey" inför turneringen här.
                        Dem verkar riktigt heta. <br/>
                        Jag har ju jobbat lite med Jimmy där, vi hade tränaruppdrag i ungdomslandslaget ihop ett tag. Så jag vet ju att han kan det här med Hockey.
                      </p>
                      <h4>Har du någon du vill lyfta lite i ditt eget lag? Någon vi ska hålla ögonen på?</h4>
                      <p>- Njaa, Alla grabbarna har ju sina egna personligheter och tricks. Så vi har inte några "stjärnor" som är mer än någon annan i den bemärkelsen.
                        Men Willy eller Lemuren skulle kunna bli skyttekung här i turneringen, då dem är finurliga framåt - samt Domino i kassen är ju som en vägg. Så där kan
                        ni kika lite om ni vill.
                      </p>
                      <h4>Sista frågan coach, Om ni skulle vinna, hur ska ni fira det?</h4>
                      <p>- Haha, lite tidigt att börja tänka på det just nu, men okey vi kör. 
                        Då blir det nog ett jävla ståhej på torget. Det var längesen bygden hade något att ställa sig bakom. Så vi får väl spruta lite skumpa där ihop
                        med fansen och sen bjuda på bullens eller något.</p>
                </section>
          </article>
          <aside className='TeamAside'>
            <h2>Tränarfoto</h2>
              <figure className="CoachPhoto">
                <img className="MarcusCoach" src={coach} alt=""/>
                <p className='CoachText'>Huvudcoach, Mr. Marcus Ronnmar</p>
              </figure>
              <h2>Ass. Tränarfoto</h2>
              <figure className="CoachPhoto">
                <img className='MarcusAssCoach' src={asscoach} alt=""/>
                <p className='AssCoachText'>Assisterande Coach, Mr. Gerry Sand</p>
              </figure>
              <h2>Logotyp</h2>
              <figure className="CoachPhoto">
                <img className='MarcusLogo' src={logo} alt=""/>
              </figure>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td className='tablePos'>M</td>
                            <td>Domino Hattsäck</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VB</td>
                            <td>Carl-Jan Klingberg</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HB</td>
                            <td>Karl Kopparkrok</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>C</td>
                            <td>Petter Fossberg</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VF</td>
                            <td>Mairo Lemur</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HF</td>
                            <td>William "Willy" Wallander</td>
                        </tr>
                    </table>
              </section>
          </aside>     
      </div>
    );
  }
  
  export default RG;