import React from 'react';
import lineup from '../../img/jj/lineup.png'
import team from '../../img/jj/team.png'

const RoadMapHockey = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                  <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className='JimmyTeam' src={team} alt="" />
                    </figure>
                </section>
                <section className='TeamTextInfo'>
                      <h2>Laginfo</h2>
                      <p>
                        RoadMap Hockey är ett helt nytt lag ihop köpt av ingen mindre än Marcus G.
                        Denna gången har han minsann inte sparat några slantar. <br/>
                        Laget spås en ljus framtid enligt många men de har egentligen inte bevisat något ännu.
                        Det är ett starkt gäng på pappret men de har ännu så länge inte en enda gemensam minut på isen.
                        <br/>
                        Vi liter helt på att spelarna har skött sin egenträning och inte slarvat med fysiken säger tränaren och lagledaren Jimmy Jonasson.
                      </p>
                      <h3>Styrkor</h3>
                      <p>Det är ett lag med handlingskraft på varje position.</p>
                      <p><b>Uffe K</b> Mål - Lagets stora trygghet skulle förvåna mig om han släpper mer än 5 mål i turneringen.</p>
                      <p><b>Anna J</b> Back - Lagets energiknippe hon kommer att gå för fullt från start och aldrig slå av på takten.</p>
                      <p><b>Peo J</b>  Back - Han har bestämt sig "Här kommer ingen jävel över bron" Lär han ha sagt.</p>
                      <p><b>Bosse B</b>  Ytter - Lagets absolut klurigaste spelare, budskap till övriga är släpp inte denna killen ur sikte.</p>
                      <p><b>Thomas S</b>  Ytter - Här är en kille som inte bara ser matchen utan fullkomligen äger matchen, räkna med denna killen i poängligan.</p>
                      <p><b>Kenneth M</b>  Center - Självklara valet en gång center alltid center inte ens i kommunvalet
                        går det att kompromissa det är centern som gäller. Alltid blicken framåt och hungrig på segrar.
                        Troligen blir han turneringens målkung.</p>
                      <h3>Svagheter</h3>
                      <p>Det finns, men de bjuder vi inte på i detta forumet.</p>
                </section>
          </article>
          <aside className='TeamAside'>
          <h2>Tränarfoto</h2>
              <figure className="CoachPhoto">   
              </figure>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td className='tablePos'>M</td>
                            <td>Ulf K.</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VB</td>
                            <td>Anna J.</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HB</td>
                            <td>Peo J.</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>C</td>
                            <td>Kenneth M.</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VF</td>
                            <td>Bosse B.</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HF</td>
                            <td>Thomas S.</td>
                        </tr>
                    </table>
              </section>
              <figure className='TeamPhoto'>
                <img className='JimmyLineUp' src={lineup} alt="" />
              </figure>
          </aside>     
      </div>
    );
  }
  
  export default RoadMapHockey;