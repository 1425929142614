import React from 'react';

function Aside() {
    return (
      <div className="Aside">
            <article>
                <section>
                    <h1>Grupp A</h1>
                    <table>
                        <tr>
                            <th></th>
                            <th>GP</th>
                            <th>W</th>
                            <th>D</th>
                            <th>L</th>
                            <th>GF</th>
                            <th>GA</th>
                            <th>GD</th>
                            <th>PTS</th>
                        </tr>
                        <tr>
                            <td>RoadMap Hockey</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>13</td>
                            <td className='tablePos'>7</td>
                            <td className='tablePos'>6</td>
                            <td className='tablePos'>6</td>
                        </tr>
                        <tr>
                            <td>Hattarna & Mössorna</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>11</td>
                            <td className='tablePos'>9</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>6</td>
                        </tr>
                        <tr>
                            <td>Legenderna</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>4</td>
                            <td className='tablePos'>6</td>
                            <td className='tablePos'>-2</td>
                            <td className='tablePos'>3</td>
                        </tr>
                        <tr>
                            <td>Inköps Finest</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>9</td>
                            <td className='tablePos'>-6</td>
                            <td className='tablePos'>3</td>
                        </tr>
                    </table>
                </section>

                <section>
                    <h1>Grupp B</h1>
                    <table>
                        <tr>
                            <th></th>
                            <th>GP</th>
                            <th>W</th>
                            <th>D</th>
                            <th>L</th>
                            <th>GF</th>
                            <th>GA</th>
                            <th>GD</th>
                            <th>PTS</th>
                        </tr>
                        <tr>
                            <td>Ronneby Gräsänder</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>10</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>7</td>
                            <td className='tablePos'>7</td>
                        </tr>
                        <tr>
                            <td>Glada Kakor</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>4</td>
                            <td className='tablePos'>6</td>
                            <td className='tablePos'>-2</td>
                            <td className='tablePos'>6</td>
                        </tr>
                        <tr>
                            <td>Big Red Machine</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>5</td>
                            <td className='tablePos'>6</td>
                            <td className='tablePos'>-1</td>
                            <td className='tablePos'>3</td>
                        </tr>
                        <tr>
                            <td>Team Brynäs</td>
                            <td className='tablePos'>3</td>
                            <td className='tablePos'>0</td>
                            <td className='tablePos'>1</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>2</td>
                            <td className='tablePos'>6</td>
                            <td className='tablePos'>-4</td>
                            <td className='tablePos'>1</td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h1>Skytteliga</h1>
                    <table>
                        <tr>
                            <th></th>
                            <th>Namn</th>
                            <th>Lag</th>
                            <th>Position</th>
                            <th>Mål</th>

                        </tr>
                        <tr>
                            <td>1.</td>
                            <td>Kenneth M</td>
                            <td>RoadMap Hockey</td>
                            <td className='tablePos'>C</td>
                            <td className='tablePos'>13</td>

                        </tr>
                        <tr>
                            <td>2.</td>
                            <td>Ian Wachtmeister</td>
                            <td>Hattarna & Mössorna</td>
                            <td className='tablePos'>C</td>
                            <td className='tablePos'>8</td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Petter Fossberg</td>
                            <td>Ronneby Gräsänder</td>
                            <td className='tablePos'>C</td>
                            <td className='tablePos'>7</td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td>Anna J</td>
                            <td>RoadMap Hockey</td>
                            <td className='tablePos'>VB</td>
                            <td className='tablePos'>4</td>
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td>"Lill-Strimma"</td>
                            <td>Legenderna</td>
                            <td className='tablePos'>HB</td>
                            <td className='tablePos'>3</td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>Janne Beatles</td>
                            <td>Big Red Machine</td>
                            <td className='tablePos'>C</td>
                            <td className='tablePos'>2</td>
                        </tr>
                        <tr>
                            <td>7.</td>
                            <td>Tommy Larsson</td>
                            <td>Big Red Machine</td>
                            <td className='tablePos'>VB</td>
                            <td className='tablePos'>2</td>
                        </tr>
                        <tr>
                            <td>8.</td>
                            <td>Carl-Jan Klingberg</td>
                            <td>Ronneby Gräsänder</td>
                            <td className='tablePos'>VB</td>
                            <td className='tablePos'>2</td>
                        </tr>
                        <tr>
                            <td>9.</td>
                            <td>Mats Fågelman</td>
                            <td>Glada Kakor</td>
                            <td className='tablePos'>VB</td>
                            <td className='tablePos'>2</td>
                        </tr>
                        <tr>
                            <td>10.</td>
                            <td>Jonas Ohnback</td>
                            <td>Inköps Finest</td>
                            <td className='tablePos'>HF</td>
                            <td className='tablePos'>1</td>
                        </tr>
                    </table>
                </section>
            </article>    
      </div>
    );
  }
  
  export default Aside;