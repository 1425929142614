import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Schedule from "./components/Schedule";
import Start from './components/start';
import Home from './components/Home';
import Rules from './components/Rules';
import RG from './components/teams/rg';
import IF from './components/teams/if';
import RoadMapHockey from './components/teams/rmh';
import Legenderna from './components/teams/leg';
import HM from './components/teams/hm';
import TheBigRedMachine from './components/teams/brm';
import TB from './components/teams/tb';
import GladaKakor from './components/teams/gk';
import News from './components/News';


function App() {
  return (
    <BrowserRouter>
    <Navbar />
      <Routes>
        <Route path="/" element={<Home />}>
        <Route index element={<Start />} />
        <Route path="Schedule" element={<Schedule />} />
        <Route path="News" element={<News />} />
        <Route path="RG" element={<RG/>} />
        <Route path="IF" element={<IF/>} />
        <Route path="RoadMapHockey" element={<RoadMapHockey/>} />
        <Route path="Legenderna" element={<Legenderna/>} />
        <Route path="HM" element={<HM/>} />
        <Route path="TheBigRedMachine" element={<TheBigRedMachine/>} />
        <Route path="GladaKakor" element={<GladaKakor/>} />
        <Route path="TB" element={<TB/>} />
        <Route path="Rules" element={<Rules />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
