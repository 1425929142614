import React from 'react';
import coach from '../../img/vk/coach.png'
import team from '../../img/vk/team.jpg'

const IF = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                  <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className="ViktorTeam" src={team} alt="" />
                    </figure>
                </section>
                <section className='TeamTextInfo'>
                      <h3>Intervju med Coachen, Sir Viktor Karlsson, inför Quality Hockey Games</h3>
                      <h4>Snart drar turneringen igång och ni går in som solklara favoriter. Vad är dina tankar om det?</h4>
                      <p>- Ja det är befogat. Det enda orosmolnet jag ser är att vi hamnade i dödens grupp. Jag trodde först
                        det var uppdelat på kvalité men sedan fick jag förklarat att det inte var en proffsgrupp och en 
                        amatörgrupp. Hade vi hamnat i andra gruppen med slagpåsarna The Big Red Machine och Team Brynäs hade det varit
                        en promenadsträcka till slutspelet.
                      </p>
                      <h4>Det har varit snack om att lottningen inte gick helt rätt till, har du någon kommentar till detta?</h4>
                      <p>- Som sagt. Det finns på förhand två lag som håller riktigt låg nivå jämfört med andra och båda dessa
                        hamnade i samma grupp som en av arrangörerna. Jag tror inte på slumpen.
                      </p>
                      <h4>Beskriv "Inköps Finest" spelare för spelare.</h4>
                      <p><b>Målvakt:</b> - I mål har vi Therese Berggren. Inköps räddningsplanka när resten gör bort sig.</p>
                      <p><b>Backar:</b> - Vi har ett stabilt packpar med grovjobbarna Mats Andersson och Jonas Ohnback. En duo med fokus på defensiven i alla lägen.</p>
                      <p><b>Forwards:</b> - Ytterforwards är jag själv för att toppa laget och sedan har vi tagit en stjärna från Polen i form utav Pawel Beta.
                      Center är lagkapten Michael Holm som håller ihop detta spretiga gäng.</p>
                      <h4>Har du någon hälsning till era fans inför turneringen?</h4>
                      <p>- Vi ska inte göra er besvikna. Jag vet att våra fans tycker lika illa om The Big Red Machine, Team Brynäs och dess tränare lika mycket som jag så om någon av dess mot förmodan tar sig till slutspel
                        ska vi göra allt för att köra över dem.
                      </p>
                </section>
          </article>
          <aside className='TeamAside'>
            <h2>Tränarfoto</h2>
            <figure className="CoachPhoto">
                <img className="ViktorCoach" src={coach} alt=""/>
              </figure>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td className='tablePos'>M</td>
                            <td>Therese Berggren</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VB</td>
                            <td>Jonas Ohnback</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HB</td>
                            <td>Mats Andersson</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>C</td>
                            <td>Michael Holm</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VF</td>
                            <td>Pawel Beta</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HF</td>
                            <td>Viktor Karlsson</td>
                        </tr>
                    </table>
              </section>
          </aside>     
      </div>
    );
  }
  
  export default IF;