import React from 'react';
import team from '../../img/ro/team.png'

const TheBigRedMachine = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                  <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className='RogerTeam' src={team} alt="team" />
                    </figure>
                </section>
                <section className='TeamTextInfo'>
                </section>
          </article>
          <aside className='TeamAside'>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td className='tablePos'>M</td>
                            <td>Röde Rolf</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VB</td>
                            <td>Tommy Larsson</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HB</td>
                            <td>Mackan</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>C</td>
                            <td>Janne Beatles</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>VF</td>
                            <td>Åke Löfdahl</td>
                        </tr>
                        <tr>
                            <td className='tablePos'>HF</td>
                            <td>Bjällermo</td>
                        </tr>
                    </table>
                    <h2>Kommentar ifrån Coachen</h2>
                      <p>
                        "Har stora förväntningar på att detta lag ta sig hela vägen. Inte en svag punkt som jag ser det. Ska bli oerhört intressant att se dom andra lagen som ska match denna femma." - <i>Roger O.</i>
                      </p>
              </section>
          </aside>     
      </div>
    );
  }
  
  export default TheBigRedMachine;