import React from 'react';

const News = () => {
    return (
      <section className="NewsWrapper">
        <section className="NewsContent">
          <section className='NewsHeader'>
            <h2>3 snabba efter gruppspelet</h2>
            <h3>Marcus Ronnmar <i>2024-02-26 - uppdaterad 2024-02-28</i></h3>
          </section>
          <section className='NewsText'>
            <h4>Gruppspelet i Quality Hockey Games spelades färdigt under måndagen, så vi passade på att slänga iväg 3 snabba frågor<br/>
            1. Vad säger ni om er insats?<br/>2. Vad var positivt och negativt i turneringen?<br/>3. Vad händer näst för ert lag?</h4>
            <h5>Viktor Karlsson, Inköps Finest:</h5>
            <p>Bedrövligt. Finns inga ursäkter.<br/><br/>
            Positivt: Målskyttet ifrån backarna<br/>
            Negativt: Lagets psyke i sista matcherna.<br/><br/>
            Det blir stora rockader. Många kommer åka rätt ut och in med nya färska spelare.</p>
          </section>
          <section className='NewsText'>
          <h5>Marcus Ronnmar, Ronneby Gräsänder:</h5>
            <p>Nej vad kan man säga, vi kom igång bra och höll fokus här i gruppspelet. Jag tycker grabbarna gör en underbar insats rätt igenom. <br/>Kändes som att vi kom närmre varandra i laget med.<br/><br/>
            Det är ju alltid positivt att få komma ut på vägen och träffa lite nya människor och spelare. Sen hela turneringsgänget här är ju underbara, man vill liksom aldrig lämna!<br/> 
            Haha om jag ska säga något negativt så tycker jag det har saknats lite publikstöd och underhållning i pauserna...<br/><br/>
            Jag och Gerry har väl fullt upp nu med att scouta och planera inför slutspelet här. Men i det närmsta för laget så ska dem få landa lite i situationen och få komma ut
            i bygden lite och visa sig.<br/>Willy och Carl-Jan brukar styra upp lite aktiviteter under lediga perioder, så se upp i byn nu..!</p>
          </section>
          <section className='NewsText'>
          <h5>Jimmy Jonasson, RoadMap Hockey:</h5>
            <p>Idag är jag nöjd med grabbarna, Thomas S hittar rätt med sina passningar och Kenneth gör ingen besviken. Idag hade även Ulf K vaknat
              på rätt sida och tätade kassen.<br/><br/>
            Har inte något negativt att säga om turneringen är väl bara lite besviken för min förlust.<br/><br/>
            Det är rast och vila som gäller, vi får ju lite kortare vila då vi spelade måndagsmatch men det ska nog lösa sig.<br/>
            Vi möter ju kakorna i 2x5 så det gäller att inte ha syra i benen.</p>
          </section>
          <section className='NewsText'>
            <h5>Joakim Ottosson, Hattarna & Mössorna:</h5>
            <p>Insatsen var fantastisk idag, dock svårt att bedöma form osv då motståndarna var av så pass dålig kvalitet.<br/><br/>
            Nästan bara positivt måste jag säga. Kvaliteten hos vissa lag hamnar dock på minuskontot.<br/><br/>
            Nu blir det direktflyg till Florida och pensionat Ice Sommerland för en riktig suparesa. Laget förtjänar det helt enkelt. (Gudrun Schyman har en vit vecka så hon blir reseledare)</p>
          </section>
          <section className='NewsText'>
            <h5>Roger Olsson, The Big Red Machine:</h5>
            <p>Katastrofal insats av Röde Rolf i kassen. Vet inte hur vår sportchef tänkte när vi hämtade in Roffe. En sak är säker och det är att vi inom styrelsen kommer ge
              oss ut på marknaden när fönstret öppnar igen. <br/>Ny goalie och sportchef ska in.<br/><br/>
            Det enda positiva jag kan hitta med detta lag som vi mönstrade är att Janne Beatles stack ut. Resten kommer höja sig med lite matcher i benen.<br/>
            Det negativa med turneringen är att arrangörerna lyckades med att få ut lagen som drog lite publik och engagemang till turneringen. Borde räcka att boka konf.rum Gö till slutspelet då turneringen tyvärr "dog".<br/><br/>
            Stort tack till arrangörerna till en välplanerad och fin turnering.<br/>Mvh Styrelsen Big Red Machine</p>
          </section>
          <section className='NewsText'>
          <h5>Lie Johnsson, Glada Kakor:</h5>
            <p>Jag är stolt över mitt lag för den fina prestation vi gjorde, det var det inte riktigt någon som trodde så vi är överraskande glada!<br/><br/>
            Positivt med turneringen var det stora engagemanget till att göra detta till ett fint och proffsigt arrangemang.<br/>
            Negativt med turneringen kan nog vara att att den är utan publik. Jag menar, hur kul hade det inte varit med stor hejaklack?!<br/><br/>
            Näst på tur för mitt lag är match i semi på fredag där vi möter en så kallad mästare, så vi får se hur det går!</p>
          </section>
        </section>


        <section className="NewsContent">
          <section className='NewsHeader'>
            <h2>Tränarpuls på utvalda efter första gruppspelsmatcherna</h2>
            <h3>Marcus Ronnmar <i>2024-02-21</i></h3>
          </section>
          <section className='NewsText'>
            <h4>Nu är turneringen igång och första gruppspelsmatcherna har spelats. Hur känns det och vad tycker du om er insats?</h4>
            <h5>Lie Johnsson, Glada Kakor:</h5>
            <p>Jadu, det känns väl okej. Vi glada kakor försöker jämt hålla upp humöret, men jag ska inte ljuga, vi har nog fått ladda upp med en och annan sockerbit.<br/>
            Insatsen där emot finns att önska mer utav. Det har som sagt blivit en och annan sockerbit och det har också bidragit till att farten på spelarna kanske inte är 
            så hög följt utav sockerkicken som nog också bidragit till en viss förvirring i laget.<br/>
            Men vi ska försöka samla oss och ladda upp på ett smart sätt inför nästa gruppspel!</p>
          </section>
          <section className='NewsText'>
            <h5>Henrik Olsson, Legenderna:</h5>
            <p>Du kan dra åt helvete.</p>
          </section>
          <section className='NewsText'>
            <h5>Ulf Gummesson, Team Brynäs:</h5>
            <p><i>"Quality Hockey Games har sökt Ulf Gummesson för kommentar utan framgång."</i></p>
          </section>
          <section className='NewsText'>
            <h5>Viktor Karlsson, Inköps Finest:</h5>
            <p><i>"Quality Hockey Games har sökt Viktor Karlsson för kommentar utan framgång."</i></p>
          </section>
        </section>
      </section>
    );
  }
  
  export default News;