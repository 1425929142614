import React from 'react';

const Rules = () => {
    return (
      <div className="Rules">
          <h1>Regler</h1>
          <ol>
          <li><h3>Matchlängd enligt följande (aktiv tid):</h3></li>
            <h4>A. Gruppspel: 1x5minuter<br/>
            B. Slutspel: 2x5minuter
          </h4>      
          <li><h3>Tiden stannar endast om pucken lämnar spelplanen under en längre tid och ingen reservpuck finns att tillgå.</h3></li>
          <li><h3>I de fall match slutar oavgjort efter ordinarie tid gäller följande:</h3></li>
            <h4>A. Gruppspel: Båda lagen belönas med 1 poäng var.<br/>
            B. Slutspel: "Golden Goal" perioder inleds (1x5min), som varar tills ett av lagen gör mål</h4>
            <li><h3>I de fall pucken studsar ut ur målburen så bedöms situationen ej som ett mål, utan spelet fortsätter.</h3></li>
            <li><h3>I de fall pucken hamnar utanför spelplanen, så sätts den i spel igen utav den närmsta observatören, där tekning inleds på tekningspunkt närmst platsen där pucken lämnade spelplanen.</h3></li>
            <li><h3>I de fall mål görs direkt via tekning i mittzon, vid t.ex. start utav period eller direkt efter mål, bedöms målet ej som godkänt utan tekning skall göras om.</h3></li>
            <li><h3>Vid lika poäng i tabell används följande ordning för att plocka fram en vinnare:</h3></li>
              <h4>A. Målskillnad<br/>
              B. Inbördes möte<br/>
              C. Flest gjorda mål<br/>
              D. Avgörande match mellan lag.</h4>
            <li><h3>Lagen får fördel av hemmaplan beroende på när dem blev inlottade i gruppen. Lag 1 har t.ex. hemmaplansfördel mot alla, medans Lag 4 aldrig får spela hemmaplan.</h3></li>
            <li><h3>Det laget som spelar på hemmaplan får alltid välja vilken sida dem vill spela på innan matchstart.</h3></li>
            <li><h3>Spelande sker på utstämplad tid.</h3></li>
          
          </ol>
      </div>
    );
  }
  
  export default Rules;