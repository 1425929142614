import React from 'react';

const Schedule = () => {
    return (
      <div className="Groups">
      <h1>Spelschema</h1>
        <article className='GroupA'>
          <section className='GroupText'>
            <h2>Grupp A</h2>
          </section>
          <section className='GroupSchema'>
            <table>
                       <h4>16 Februari</h4> 
                        <tr>
                            <th>Klockan</th>
                            <th>Hemmalag</th>
                            <th></th>
                            <th>Bortalag</th>
                            <th>Resultat</th>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.32</td>
                            <td className='tableLagnamn'>Hattarna och Mössorna</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>RoadMap Hockey</td>
                            <td className='tableMål'>3 : 8</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.46</td>
                            <td className='tableLagnamn'>Legenderna</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Inköps Finest</td>
                            <td className='tableMål'>0 : 2</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>12.00</td>
                            <td className='tableLagnamn'>Hattarna och Mössorna</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Legenderna</td>
                            <td className='tableMål'>3 : 1</td>
                        </tr>
                        <br/>
                        <h4>23 Februari</h4>
                        <tr>
                            <th>Klockan</th>
                            <th>Hemmalag</th>
                            <th></th>
                            <th>Bortalag</th>
                            <th>Resultat</th>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.37</td>
                            <td className='tableLagnamn'>RoadMap Hockey</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Legenderna</td>
                            <td className='tableMål'>1 : 3</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>14.04</td>
                            <td className='tableLagnamn'>Hattarna och Mössorna</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Inköps Finest</td>
                            <td className='tableMål'>5 : 0</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>14.13</td>
                            <td className='tableLagnamn'>RoadMap Hockey</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Inköps Finest</td>
                            <td className='tableMål'>4 : 1</td>
                        </tr>
                    </table>
          </section>
        </article>
        <article className='GroupB'>
          <section className='GroupText'>
            <h2>Grupp B</h2>
          </section>
          <section className='GroupSchema'>
            <table>
                       <h4>16 Februari</h4> 
                        <tr>
                            <th>Klockan</th>
                            <th>Hemmalag</th>
                            <th></th>
                            <th>Bortalag</th>
                            <th>Resultat</th>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.39</td>
                            <td className='tableLagnamn'>The Big Red Machine</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Team Brynäs</td>
                            <td className='tableMål'>3 : 0</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.53</td>
                            <td className='tableLagnamn'>Glada Kakor</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Ronneby Gräsänder</td>
                            <td className='tableMål'>0 : 5</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>12.07</td>
                            <td className='tableLagnamn'>Team Brynäs</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Glada Kakor</td>
                            <td className='tableMål'>1 : 2</td>
                        </tr>
                        <br/>
                        <h4>23 Februari</h4>
                        <tr>
                            <th>Klockan</th>
                            <th>Hemmalag</th>
                            <th></th>
                            <th>Bortalag</th>
                            <th>Resultat</th>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.44</td>
                            <td className='tableLagnamn'>Team Brynäs</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Ronneby Gräsänder</td>
                            <td className='tableMål'>1 : 1</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.51</td>
                            <td className='tableLagnamn'>The Big Red Machine</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Glada Kakor</td>
                            <td className='tableMål'>0 : 2</td>
                        </tr>
                        <tr>
                            <td className='tableVS'>11.58</td>
                            <td className='tableLagnamn'>The Big Red Machine</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn'>Ronneby Gräsänder</td>
                            <td className='tableMål'>2 : 4</td>
                        </tr>
                    </table>
          </section>
        </article>
        <article className='PlayoffSchema'>
          <h1>Slutspel</h1>
          <h4>1 Mars</h4>
          <table>
                        <tr>
                            <th></th>
                            <th>Klockan</th>
                            <th>Hemmalag</th>
                            <th></th>
                            <th>Bortalag</th>
                            <th>Resultat</th>
                        </tr>
                        <tr>
                            <td className='matchTyp'>Semifinal 1</td>
                            <td className='tableVS'>11.17</td>
                            <td className='tableLagnamn1'>RoadMap Hockey</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn1'>Glada Kakor</td>
                            <td className='tableMål'>8 : 2</td>
                        </tr>
                        <tr>
                            <td className='matchTyp'>Semifinal 2</td>
                            <td className='tableVS'>Uppskj.</td>
                            <td className='tableLagnamn1'>Ronneby Gräsänder</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn1'>Hattarna & Mössorna</td>
                            <td className='tableMål'>- : -</td>
                        </tr>
                        <tr>
                            <td className='matchTyp'>Bronsmatch</td>
                            <td className='tableVS'>Uppskj.</td>
                            <td className='tableLagnamn1'>Glada Kakor</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn1'>Förlorare, Semifinal 2</td>
                            <td className='tableMål'>- : -</td>
                        </tr>
                    </table>
                    <h4>8 Mars</h4>
          <table>
                        <tr>
                            <th></th>
                            <th>Klockan</th>
                            <th>Hemmalag</th>
                            <th></th>
                            <th>Bortalag</th>
                            <th>Resultat</th>
                        </tr>
                        <tr>
                            <td className='matchTyp'>Final</td>
                            <td className='tableVS'>11.17</td>
                            <td className='tableLagnamn1'>Vinnare, Semifinal 1</td>
                            <td className='tableVS'> - </td>
                            <td className='tableLagnamn1'>Vinnare, Semifinal 2</td>
                            <td className='tableMål'>- : -</td>
                        </tr>
                    </table>
        </article>
  </div>
    );
  }
  
  export default Schedule;