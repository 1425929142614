import React from 'react';
import coach from '../../img/lj/coach.png'
import team from '../../img/lj/team.PNG'
import lineup from '../../img/lj/lineup.PNG'


const GladaKakor = () => {
    return (
      <div className="TeamInfo">
         <article>
                <section className='TeamText'>
                <h2>Lagfoto</h2>
                    <figure className='TeamPhoto'>
                      <img className="LieTeam" src={team} alt="" />
                    </figure>
                    <figure className='TeamPhoto'>
                      <img className="LieTeam" src={lineup} alt="" />
                    </figure>
                </section>
          </article>
          <aside className='TeamAside'>
          <h2>Tränarfoto</h2>
              <figure className="CoachPhoto">
                <img className='LieCoach' src={coach} alt=""/>
              </figure>
              <section className='LineUp'>
                <h2>Laguppställning</h2>
                <table>
                        <tr>
                            <th>Nr.</th>
                            <th>Position</th>
                            <th>Namn</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td className='tablePos'>M</td>
                            <td>Esmeralda Virrpanna</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td className='tablePos'>VB</td>
                            <td>Mats Fågelman</td>
                        </tr>
                        <tr>
                            <td>23</td>
                            <td className='tablePos'>HB</td>
                            <td>Calle Släpfot</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td className='tablePos'>C</td>
                            <td>Henke Hockeyman</td>
                        </tr>
                        <tr>
                            <td>32</td>
                            <td className='tablePos'>VF</td>
                            <td>Pelle Kanin</td>
                        </tr>
                        <tr>
                            <td>43</td>
                            <td className='tablePos'>HF</td>
                            <td>Putte Plåt</td>
                        </tr>
                    </table>
                    <br/>
                    <h2>Styrkor</h2>
                    <p>Att dem är söta</p>
                    <h2>Svagheter</h2>
                    <p>Fika och godsaker.</p>
                    <h2>Chanser i turneringen</h2>
                    <p>20% chans till vinst</p>
              </section>
          </aside>     
      </div>
    );
  }
  
  export default GladaKakor;