import React from 'react';
import Logo from '../img/logo.png'
import { NavLink } from "react-router-dom";
import Teamsbar from './Teamsbar';
import Newsbar from './Newsbar';

const Navbar = () => {
    return (
        <div className="Navbar">
            <nav>
            <figure className='Logo'>
                <img src={Logo} alt="Logotype for Tournament"/>
            </figure> 
                <section className='NavBarLinks'>
                <ul>
                    <li><NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Hem</NavLink></li>
                    <li><NavLink to="News" className={({ isActive }) => (isActive ? 'active' : '')}>Newsroom</NavLink></li>
                    <li><NavLink to="Schedule" className={({ isActive }) => (isActive ? 'active' : '')}>Spelschema</NavLink></li>
                    <li><NavLink to="Rules" className={({ isActive }) => (isActive ? 'active' : '')}>Regler</NavLink></li>
                </ul>
                </section>
            </nav>
            <Teamsbar />
            <Newsbar/>
        </div>
    );
  }
  
  export default Navbar;